<template lang="pug">
nav.FcwFooterLinks
  h4.FcwFooterLinks__title {{ title }}
  .FcwFooterLinks__items
    template(
      v-for="(link, index) in items"
      :key="index"
    )
      .FcwFooterLinks__item(v-if="isLinkVisible(link)")
        FcwButtonLink(
          v-if="link.type === 'button'"
          :location="getLinkLocation(link)"
        ) {{ link.label }}
        template(v-else) 
          FIcon(
            v-if="link.icon"
            :name="link.icon"
            color="primary--light-1"
          )
          span.FcwFooterLinks__itemText(
            v-if="link.type === 'text'"
            v-html="link.label"
          )
          FcwRouterLink(
            v-else
            :location="getLinkLocation(link)"
            hide-arrow
            color="neutral--light-5"
            :external="link.type === 'external-link'"
          ) {{ getLinkLabel(link) }}
</template>

<style lang="stylus">
.FcwFooterLinks
  &__title
    color var(--color--primary--light-1)
    font-weight 400
    margin-bottom rem(16)

  &__items
    display flex
    flex-direction column
    align-items flex-start
    gap rem(16)

  &__item
    display flex
    gap rem(4)

  &__itemText
    font-style normal
</style>

<script setup lang="ts">
import FcwButtonLink from '@/components/molecules/FcwButtonLink.vue';

import type { RouteLocationRaw } from 'vue-router';

export interface FooterLink {
  /**
   * Displayed label of the link
   */
  label: string;
  /**
   * Link location
   */
  location: RouteLocationRaw;
  /**
   * Link type if specific
   */
  type?:
    | 'external-link'
    | 'internal-link'
    | 'email'
    | 'phone'
    | 'text'
    | 'button';
  /**
   * Link icon
   */
  icon?: Icon;
  /**
   * Internal page
   */
  internalPage?:
    | 'sharing'
    | 'leasing'
    | 'about'
    | 'home'
    | 'terms-and-conditions'
    | 'privacy-policy'
    | 'blog'
    | 'help'
    | 'profile'
    | 'map';
  /**
   * Dynamic page slug
   */
  dynamicPageSlug?: string;
  /**
   * Blog post slug
   */
  blogPostSlug?: string;
}

export interface FcwFooterLinksProps {
  /**
   * Title of the links
   */
  title?: string;
  /**
   * Footer links
   */
  items?: FooterLink[];
}

withDefaults(defineProps<FcwFooterLinksProps>(), {
  title: '',
  items: () => [],
});

const { t } = useI18n();

function getInternalPageLabel(
  internalPage: FooterLink['internalPage']
): string {
  if (!internalPage) {
    return '';
  }

  const internalPageToLink: Record<
    NonNullable<FooterLink['internalPage']>,
    string
  > = {
    sharing: t('nav__sharing'),
    leasing: t('nav__leasing'),
    about: t('nav__about'),
    home: t('nav__home'),
    'terms-and-conditions': t('footer__terms_and_conditions'),
    'privacy-policy': t('footer__privacy_policy'),
    blog: t('nav__blog'),
    help: t('nav__help__title'),
    profile: t('nav__sharing'),
    map: t('common__my_profile'),
  };

  return internalPageToLink[internalPage];
}

function getLinkLabel(link: FooterLink): string {
  if (!!link?.internalPage && !link.label) {
    return getInternalPageLabel(link.internalPage);
  }

  return link.label;
}

function getLinkLocation(link: FooterLink): RouteLocationRaw {
  switch (true) {
    case !!link.internalPage:
      return { name: link.internalPage };
    case !!link.dynamicPageSlug:
      return {
        name: 'showcase-slug',
        params: { slug: link.dynamicPageSlug },
      };
    case !!link.blogPostSlug:
      return {
        name: `showcase-blog-slug`,
        params: { slug: link.blogPostSlug },
      };
    case typeof link.location === 'string':
      return { name: link.location };
    default:
      return link.location;
  }
}

function isLinkVisible(link: FooterLink): boolean {
  return (!!link.label && !link.internalPage) || !!link.internalPage;
}
</script>
